/* home.css */
.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: fadeInZoomOut 3s ease-in-out forwards; /* Combine fade-in and fade-out */
  }
  
  .splash-logo {
    width: 200px; /* Adjust size as needed */
  }
  
  /* Keyframe animations */
  @keyframes fadeInZoomOut {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1.1); /* Slight zoom out for effect */
    }
  }
  