/* Navbar.css */

/* Navbar container */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    background-color: #ffffff; /* Customize the navbar background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
    font-family: sans-serif;
  }
  
  /* Logo on the left */
  .nav-left {
    flex: 1; /* Allow logo to stay on the left */
  }
  
  .nav-logo {
    height: 90px; /* Adjust size as needed */
  }
  
  /* Navigation options in the center */
  .nav-options {
    list-style: none;
    display: flex;
    gap: 30px; /* Adjust spacing between options */
    margin: 0;
    padding: 0;
    flex: 2; /* Center the nav options */
    justify-content: center; /* Align options to the center */
  }
  
  .nav-item {
    cursor: pointer;
    font-size: 15px;
    
    color: black; /* Customize text color */
    transition: color 0.3s ease;
  }
  
  /* Base styling for the navigation item */
.nav-item {
    position: relative;
    display: inline-block;
    padding-bottom: 5px; /* Space for the underline */
  }
  
  /* Before pseudo-element for the underline */
  .nav-item::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px; /* Thickness of the underline */
    bottom: 0;
    left: 0;
    background-color: currentColor; /* Matches the text color */
    transition: width 0.3s ease-in-out; /* Animation */
  }
  
  /* Hover effect for the underline */
  .nav-item:hover::before {
    width: 100%; /* Extends the underline */
  }
  
  
  /* Right-side container for buttons */
  .nav-right {
    display: flex;
    align-items: center;
    gap: 15px;
    flex: 1; /* Allow buttons to stay on the right */
    justify-content: flex-end; /* Align buttons to the right */
  }
  
  /* Buttons for login and signup */
  .nav-btn {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  /* Login button styling */
  .login-btn {
    background-color: black; /* Button background color */
    color: #fff;
  }
  
  .login-btn:hover {
    background-color: white;
    color: black; /* Darker color on hover */
    border-color: black;
    border: 1px solid;
  }
  
  /* Signup button styling */
  .signup-btn {
    background-color: white; /* Button background color */
    color: black;
    border: 1px solid black;


  }
  
  .signup-btn:hover {
    background-color: black; /* Darker color on hover */
    color: white;
    border: 1px solid black;
  }

  @media (max-width: 768px) {
    /* Stack navbar items vertically */
    .navbar {
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }
  
    /* Center the logo */
    .nav-left {
      text-align: center;
      margin-bottom: 10px;
    }
  
    /* Navigation options */
    .nav-options {
      flex-direction: column; /* Stack items vertically */
      gap: 10px; /* Adjust spacing for vertical layout */
      margin-bottom: 10px;
    }
  
    /* Right-side container for buttons */
    .nav-right {
      justify-content: center; /* Center the buttons */
      margin-bottom: 10px;
    }
  
    /* Adjust button size */
    .nav-btn {
      padding: 10px 20px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    /* Further adjustments for very small screens */
    .nav-logo {
      height: 60px; /* Reduce logo size */
    }
  
    /* Navigation item font size */
    .nav-item {
      font-size: 14px;
    }
  
    /* Adjust button padding */
    .nav-btn {
      padding: 8px 15px;
      font-size: 12px;
    }
  }
  